jQuery(function ($) {
    smoothScroll.init({
        speed: 1000, // Integer. How fast to complete the scroll in milliseconds
        easing: 'easeInOutQuad', // Easing pattern to use
        updateURL: true, // Boolean. Whether or not to update the URL with the anchor hash on scroll
        offset: 97, // Integer. How far to offset the scrolling anchor location in pixels
        callbackBefore: function (toggle, anchor) {
        }, // Function to run before scrolling
        callbackAfter: function (toggle, anchor) {
        } // Function to run after scrolling
    });


    $(window).on("load", function () {
        var hash = window.location.hash;
        var offset = 97;
        var element = $(hash);

        if (element.length) {
            $('html,body').animate({scrollTop: element.offset().top - offset}, 1000);
        } else {
            $('html,body').animate({scrollTop: $('body').offset().top - offset}, 1000);
        }
    });
});


